import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
const Login = () => {
  return (
    <>
      <Container fluid>
        <Header></Header>
        <Container>
          <h1 style={{ fontFamily: "Roboto", fontWeight: "700", textAlign: "center", marginTop: "40px" }}>Login</h1>
          <Row>
            <Col md={3} style={{ marginBottom: "40px" }}>
            </Col>
            <Col md={6} style={{ marginBottom: "40px" }}>
              <Form style={{ width: "100%", padding: "20px", background: "#d9d9d9" }}>
                <Form.Group className="mb-3" controlId="formBasicText">
                  <Form.Label>Username</Form.Label>
                  <Form.Control type="text" placeholder="Enter Username" />

                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <Form.Control type="password" placeholder="Password" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <Form.Check type="checkbox" label="Remember Me" />
                </Form.Group>
                <Button variant="primary" type="submit">
                  Submit
  </Button>
              </Form>
            </Col>
            <Col md={3} style={{ marginBottom: "40px" }}>
            </Col>
          </Row>
        </Container>
        <Footer></Footer>
      </Container>
    </>
  );
}

export default Login;