import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
const Signup = () => {
  return (
    <>
    <Container fluid>
      <Header></Header>
      <Container>
        <h1 style={{fontFamily:"Roboto", fontWeight:"700", textAlign:"center", marginTop:"40px"}}>Register</h1>
        <p style={{fontFamily:"Roboto", fontWeight:"300", textAlign:"center", marginBottom:"40px"}}>Create your Account</p>
<Row>
  <Col md={3} style={{marginBottom:"40px"}}>
  </Col>
  <Col md={6} style={{marginBottom:"40px"}}>

  <Form style={{width:"100%", padding:"20px", background:"#d9d9d9"}}>
  <Row className="mb-3">
    <Form.Group as={Col} controlId="formGridEmail">
      <Form.Label>Email</Form.Label>
      <Form.Control type="email" placeholder="Enter email" />
    </Form.Group>

    <Form.Group as={Col} controlId="formGridPassword">
      <Form.Label>Password</Form.Label>
      <Form.Control type="password" placeholder="Password" />
    </Form.Group>
  </Row>

  <Form.Group className="mb-3" controlId="formGridAddress1">
    <Form.Label>Address</Form.Label>
    <Form.Control placeholder="1234 Main St" />
  </Form.Group>

  <Form.Group className="mb-3" controlId="formGridAddress2">
    <Form.Label>Address 2</Form.Label>
    <Form.Control placeholder="Apartment, studio, or floor" />
  </Form.Group>

  <Row className="mb-3">
    <Form.Group as={Col} controlId="formGridCity">
      <Form.Label>City</Form.Label>
      <Form.Control />
    </Form.Group>

    <Form.Group as={Col} controlId="formGridState">
      <Form.Label>State</Form.Label>
      <Form.Select defaultValue="Choose...">
        <option>Choose...</option>
        <option>...</option>
      </Form.Select>
    </Form.Group>

    <Form.Group as={Col} controlId="formGridZip">
      <Form.Label>Zip</Form.Label>
      <Form.Control />
    </Form.Group>
  </Row>

  <Form.Group className="mb-3" id="formGridCheckbox">
    <Form.Check type="checkbox" label="Check me out" />
  </Form.Group>

  <Button variant="primary" type="submit">
    Submit
  </Button>
</Form>



 
 
  </Col>
  <Col md={3} style={{marginBottom:"40px"}}>
  </Col>
</Row>
      </Container>
      <Footer></Footer>
    </Container>
    </>
  );
}

export default Signup;