import React from "react";
import Header from "./Header";
import Slider from "./Slider";
import Footer from "./Footer";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
const text = {
  fontSize: "1em", lineHeight: "1.8em", fontFamily:"Roboto", fontWeight:"300"
}
const card = {
  background:"#005890",
  color:"#fff",
  display:"table",
  borderRadius:"10px",
  padding: "5px",
  margin:"20px auto",
  width:"80%",
  textDecoration:"none",
  borderSpacing: "20px"
}
const cardIcon={
  display:"table-cell",
  fontSize:"3em",
  marginRight:"40px"
}
const cardText={
  display:"table-cell",
  fontSize:"1.5em",
  verticalAlign: "top",
  lineHeight: "22px",
  textAlign:"left",
  width:"100%"
}
const Home = () => {
  return (
    <>
    <Container fluid>
    <Header></Header>
    <Slider></Slider>

    <Container>
  <Row style={{marginTop:"100px"}}>
    <Col md={6} style={{marginBottom:"30px"}}>
    <h3 style={{fontSize:"2.1em", margin: "1em 0", color:"#005890", fontWeight:"700", fontFamily:"Roboto"}}>Welcome to The Solution Point</h3>
            <p style={text}>We envision to provide most accurate and vital information to students at effective cost. We have highly experienced and skilled staff to handel our screening process as a model of excellence and value. We excell in providing proper guidance to students for making their career, counseling, admission in varities of courses and solving all types of officially board, Council or University Problems.</p>
            <Button variant="primary">Read More</Button>
    </Col>
    <Col md={6}><Image src="./1.jpg" fluid /></Col>
  </Row>
  <Row style={{marginTop:"100px"}}>
  <Col md={6}><Image src="./team.jpg" fluid /></Col>
    <Col md={6} style={{marginBottom:"30px"}}>
    <h3 style={{fontSize:"2.1em", margin: "1em 0", color:"#005890", fontWeight:"700", fontFamily:"Roboto"}}>Why The Solution Point?</h3>
            <p style={text}><i className="fa fa-hand-o-right"></i> Ensuring you have the highest quality staff to resolve your admission problem.</p>
            <p style={text}><i className="fa fa-hand-o-right"></i> Skilled staffs to guide you and counsel you for your educational career.</p>
            <p style={text}><i className="fa fa-hand-o-right"></i> We deal with varities of courses to match with all students.</p>
            <p style={text}><i className="fa fa-hand-o-right"></i> Over 35000+ students have been benefitted till now.</p>
            <Button variant="primary">Read More</Button>
    </Col>
      </Row>
      
      <Row style={{width:"100%", height:"2px", background:"#999", marginTop: "30px"}}></Row>
      <h2 style={{textAlign:"center", fontFamily:"Roboto", fontWeight:"700", fontSize:"2.5em", lineHeight:"3em"}}>Our Services</h2>
      <Row style={{marginTop:"50px"}}>
      <Col md={4}>
      <a href="#" style={card}><span className="fa fa-user-secret" style={cardIcon}></span><span style={cardText}>Proper Guidance</span></a>
                      <a href="#" style={card}><span className="fa fa-book" style={cardIcon}></span><span style={cardText}>Career Counselling</span></a>
                      <a href="#" style={card}><span className="fa fa-binoculars" style={cardIcon}></span><span style={cardText}>Investigations</span></a>
                      {/* <a href="#" style={card}><span className="fa fa-hourglass-start" style={cardIcon}></span><span style={cardText}>Seeding</span></a>
                      <a href="#" style={card}><span className="fa fa-users" style={cardIcon}></span><span style={cardText}>Employee Verification</span></a> */}
                
      </Col>
      <Col md={4}><Image src="./service.jpg" style={{marginTop:55}} fluid /></Col>
      <Col md={4}>
      <a href="#" style={card}><span style={cardText}>Admissions</span><span className="fa fa-clipboard" style={cardIcon}></span></a>
                      <a href="#" style={card}><span style={cardText}>Admission Problem Solving</span><span className="fa fa-random" style={cardIcon}></span></a>
                      <a href="#" style={card}><span style={cardText}>Document Verification</span><span className="fa fa-flag-checkered" style={cardIcon}></span></a>
                      {/* <a href="#" style={card}><span style={cardText}>Address Verification</span><span className="fa fa-home" style={cardIcon}></span></a>
                      <a href="#" style={card}><span style={cardText}>Profile Checks</span><span className="fa fa-user" style={cardIcon}></span></a> */}
      </Col>
      </Row>
</Container>

      <Footer></Footer>
      </Container>
    </>
  );
}

export default Home;
