import React from "react";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import "bootstrap/dist/css/bootstrap.css";

const Menu = () => {
  return (
    <>
  <Navbar expand="lg" style={{position:"absolute", top:'0', right:'25px', paddingTop:"15px"}}>
  <Navbar.Toggle aria-controls="navbarScroll" style={{marginTop:"-85px"}} />
  <Navbar.Collapse id="navbarScroll" >
    <Nav
      className="mr-auto my-2 my-lg-0"
      style={{ background:"#fff", marginLeft: "-90px", paddingLeft: "12px", marginTop: "-60px" }}
      // navbarScroll
    >
      <Nav.Link href="/">Home</Nav.Link>
      <Nav.Link href="/About">About Us</Nav.Link>
      <Nav.Link href="/Contact">Contact Us</Nav.Link>
      <Nav.Link href="/Gallery">Gallery</Nav.Link>
      <Nav.Link href="/Login">Login</Nav.Link>
      <Nav.Link href="/Signup">Register</Nav.Link>
    </Nav>
  </Navbar.Collapse>
</Navbar>

</>
  );
}

export default Menu;