import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from "react-router-dom";
const footerTitle={
    color:"#c0c0c0",
    fontWeight:"700",
    fontFamily:"Roboto"
}
const footerText={
    color:"#c0c0c0",
    fontWeight:"300",
    fontFamily:"Roboto",
    textAlign:"justify",
    margin: 0
}
const footerLink={
    color:"#c0c0c0",
    fontWeight:"300",
    fontFamily:"Roboto",
    textAlign:"justify",
    display:"block",
    textDecoration:"none",
    borderBottom:"1px dashed #c0c0c0",
    lineHeight: "3em"
}
const copyright={
    color:"#c0c0c0",
    fontWeight:"300",
    fontFamily:"Roboto",
    lineHeight: "3em",
    textAlign:"center"
}
const Footer = () => {
  return (
      <>
      <Row style={{background:"#393939", padding:"20px 0" }}>
          <Container>
          <Container>
              <Row>
                  <Col md={5}>
                  <h2 style={footerTitle}>The Solution Point</h2>
                <p style={footerText}><span style={{lineHeight:"2em"}}>We envision to provide most accurate and vital information to students at effective cost. We have highly experienced and skilled staff to handel our screening process as a model of excellence and value.</span></p>
          
                  </Col>
                  <Col md={3}>
                  <h4 style={footerTitle}>Site Map</h4>
               <Link to="/" style={footerLink}>Home</Link>  
               <Link to="/About" style={footerLink}>About</Link> 
               <Link to="/Contact" style={footerLink}>Contact</Link> 
                  </Col>
                  <Col md={4}>
                  <h4 style={footerTitle}>Contact Us</h4>
                <p style={footerText}><span style={{fontWeight:"700"}}><i className="fa fa-map-marker"></i> The Solution Point - Rourkela</span><br/>
                SHOP No. 5, JAGANNATH TEMPLE MARKET COMPLEX, SEC-3,ROURKELA - 769002</p>
                <Row>
                    <Col xs={6}><p style={footerText}><i class="fa fa-fax"></i> +91 7978321177</p></Col>
                    <Col xs={6}><p style={footerText}><i class="fa fa-fax"></i> +91 9937267787</p></Col>
                </Row>
                {/* <Row>
                <Col xs={6}><p style={footerText}><i class="fa fa-fax"></i> 011-41546893</p></Col>
                <Col xs={6}><p style={footerText}><i class="fa fa-fax"></i> 011-41654233</p></Col>
                </Row> */}
                <p style={footerText}><i className="fa fa-envelope" ></i> solutionpointrkl@gmail.com</p>
                  </Col>
              </Row>
          </Container>
          </Container>
      </Row>
      <Row style={{background:"#1f1f1f", padding:"20px 0" }}>
          <Container>
          <Container>
          <p style={copyright}>All Rights Reserved. &copy; 2021 The Solution Point - Rourkela</p>
              </Container>
              </Container>
              </Row>
</>

  );
}

export default Footer;