import React from "react";
import Menu from "./Menu";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const logo = {
	background:"url(./logo.png)", 
	backgroundSize:"50px", 
	backgroundRepeat:"no-repeat", 
	fontSize:"2em", 
	fontSize: "1.5em",
    backgroundPosition: "0 50%", 
	paddingLeft: "60px", 
	lineHeight: "3em", 
	display: "block", 
	textDecoration:"none", 
	color:"#000", 
	fontFamily: "Roboto",
    fontWeight: "900"
}
const social_icon = {
	display:"inline-block", 
	color: "#fff", 
	textDecoration:"none",
	margin: "5px",
	fontSize: "1.1em"
}
const top_link = {
	color:"#fff",
	fontWeight: "500",
    fontFamily: "Roboto",
    textDecoration: "none",
    marginRight: "30px",
	whiteSpace: "nowrap"
}
const Header = () => {
  return (
<>

  <Row style={{background:"#005890"}}>
    <Col md={12}>
	<Container>
		<Row>
			<Col md={5}>
			<a href="./" className="fa fa-facebook-square" style={social_icon}></a>
	<a href="./" className="fa fa-instagram" style={social_icon}></a>
	<a href="./" className="fa fa-linkedin-square" style={social_icon}></a>
	<a href="./" className="fa fa-twitter-square" style={social_icon}></a>
	<a href="./" className="fa fa-rss-square" style={social_icon}></a>
			</Col>
			<Col md={7}>
			<a href="./" style={top_link}><i className="fa fa-envelope" ></i> solutionpointrkl@gmail.com</a>
	<a href="./" style={top_link}><i className="fa fa-fax" ></i> +91 7978321177</a>
	<a href="./" style={top_link}><i className="fa fa-fax" ></i> +91 9937267787</a> 
			</Col>
		</Row>
	</Container>
	</Col>
  </Row>
  <Row style={{boxShadow:"0px 0px 8px #000", position: "relative", zIndex:5}}>
	  <Col lg={4}>
	  <a href="./" style={logo}>The Solution Point</a>
	  </Col>
	  <Col lg={6} style={{position:"relative"}}><Menu></Menu></Col>
  </Row>
</>
  );
}

export default Header;