import React from "react";
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from './components/Home';
import Contact from './components/Contact';
import About from './components/About';
import Login from "./components/login";
import Signup from "./components/Signup";
import Gallery from "./components/Gallery";
import firebase from 'firebase/app';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyBGHu-mqoitAZR4yGyyU2QdqQepGXJ0Ajc",
  authDomain: "solutionpointrourkela.firebaseapp.com",
  projectId: "solutionpointrourkela",
  storageBucket: "solutionpointrourkela.appspot.com",
  messagingSenderId: "881854551905",
  appId: "1:881854551905:web:814681922b9ff10aaf9720"
};
firebase.initializeApp(firebaseConfig);
const App = () => {
  return (
<>

  <React.StrictMode>
       <Router>
      <Switch>
        <Route path="/Gallery" component={Gallery} />
        <Route path="/Contact" component={Contact} />
        <Route path="/About" component={About} /> 
        <Route path="/Login" component={Login} />  
        <Route path="/Signup" component={Signup} />        
        <Route exact path="/" component={Home} />
      </Switch>
    </Router>
  </React.StrictMode>

</>
  );
}

export default App;
