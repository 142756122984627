import React, { useEffect, useState, useMemo } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
const aboutTitle={
  fontSize: "2em",
  fontFamily: "Roboto",
  fontWeight: 700,
  marginTop: "20px"
}
const aboutText={
  fontSize: "1.3em",
  fontFamily: "Roboto",
  fontWeight: 300,
  textAlign:"justify"
}
function Gallery () {
  const [imgUrl, setImgUrl] = React.useState('');
  const [openModal, setOpenModal] = React.useState(false);

function handleClick(name){
setImgUrl(name)
setOpenModal(true)
}
if(openModal){
return(
  <Modal.Dialog fullscreen={true}>
  <Modal.Header closeButton onClick={()=>setOpenModal(false)}>
    {/* <Modal.Title>Modal title</Modal.Title> */}
  </Modal.Header>

  <Modal.Body style={{marginLeft:"auto",marginRight:"auto"}}>
  <Image src={imgUrl} />
  </Modal.Body>

  {/* <Modal.Footer>
    <Button variant="secondary">Close</Button>
    <Button variant="primary">Save changes</Button>
  </Modal.Footer> */}
</Modal.Dialog>
)
}
  return (
    <>
    <Container fluid>
    <Header></Header>
      <Container>
      <h2 style={aboutTitle}>The Solution Point - Gallery</h2>
      <Row style={{marginBottom:10}}>
      <Col md={4}><Image style={{height:200, width:400, cursor:'pointer'}} src="./1.jpeg" fluid onClick={()=>handleClick('./1.jpeg')}/></Col>
      <Col md={4}><Image style={{height:200, width:400, cursor:'pointer'}} src="./2.jpeg" fluid onClick={()=>handleClick('./2.jpeg')} /></Col>
      <Col md={4}><Image style={{height:200, width:400, cursor:'pointer'}} src="./3.jpeg" fluid onClick={()=>handleClick('./3.jpeg')} /></Col>

      </Row>
      <Row style={{marginBottom:10}}>
      <Col md={4}><Image style={{height:200, width:400, cursor:'pointer'}} src="./4.jpeg" fluid onClick={()=>handleClick('./4.jpeg')} /></Col>
      <Col md={4}><Image style={{height:200, width:400, cursor:'pointer'}} src="./5.jpeg" fluid onClick={()=>handleClick('./5.jpeg')} /></Col>
      <Col md={4}><Image style={{height:200, width:400, cursor:'pointer'}} src="./6.jpeg" fluid onClick={()=>handleClick('./6.jpeg')} /></Col>

      </Row>
      <Row style={{marginBottom:10}}>
      <Col md={4}><Image style={{height:200, width:400, cursor:'pointer'}} src="./7.jpeg" fluid onClick={()=>handleClick('./7.jpeg')} /></Col>
      <Col md={4}><Image style={{height:200, width:400, cursor:'pointer'}} src="./8.jpeg" fluid onClick={()=>handleClick('./8.jpeg')} /></Col>
      <Col md={4}><Image style={{height:200, width:400, cursor:'pointer'}} src="./9.jpeg" fluid onClick={()=>handleClick('./9.jpeg')} /></Col>

      </Row>

      </Container>
      <Footer></Footer>
    </Container>
    </>
  );
}

export default Gallery;