import React, { useEffect, useState, useMemo } from "react";
import GoogleMapReact from 'google-map-react';
// import Marker from './Marker';
import { GoogleMap, LoadScript, Marker, InfoWindow, useJsApiLoader } from '@react-google-maps/api';
const google = window.google;
const AnyReactComponent = ({ text }) => <div>{text}</div>;
export default function SimpleMap() {
  const [zoomLevel, setZoomLevel] = React.useState(10);

  const defaultProps = {
    center: {
      lat: 22.2236285,
      lng: 84.7612331
    },
    zoom: 11
  };
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: ""
  })
  const [map, setMap] = useState(null)

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map)
  }, [])
  useEffect(() => {
   setTimeout(() => {
     setZoomLevel(15)
   }, 2000);
  }, []);
  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])
  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '360px', width: '100%' }}>
     {isLoaded ?<GoogleMap
        mapContainerStyle={{
          width: '100%',
          height: '400px',
        }}
        zoom={zoomLevel}
        onLoad={onLoad}
        onUnmount={onUnmount}
        center={{ lat: 22.2484768, lng: 84.8768729 }}>
        <Marker key={1}
          title={"The Solution Point"}
          position={{ lat: 22.2484768, lng: 84.8768729 }}

        // onClick={() => onSelect(item)}
        />
      </GoogleMap>:<></>}
    </div>
  );
}