import React, { useEffect, useState, useMemo } from "react";
import Header from "./Header";
import Footer from "./Footer";
import Map from "./Map";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

function Contact () {
  const [userId, setUserId] = React.useState(null);
  const [name, setName] = React.useState('');
  const [phone, setPhone] = React.useState();
  const [email, setEmail] = React.useState('');
  const [desc, setDesc] = React.useState('');

useEffect(() => {
  firebase.auth().signInWithEmailAndPassword('mahesh.gr8@gmail.com','test1234').then((res) => {
    console.log("legged in user ",res.user.uid)
    setUserId(res.user.uid)
  }).catch((error) => {
    console.log(error);
    alert("Something went wrong.. Please call Us..!");
  })
}, []);
function handleSubmit(){
  console.log("Set submit val ", name,phone,email,desc)
  var obj={
    name:name,
    phoneNumber:phone,
    email:email,
    desc:desc,
    createdOn:new Date()
  }
  firebase.firestore().collection("enquiry").add(obj).then(function () {
    console.log("Document successfully updated!");
    alert("Your enquiry has been successfully submitted.. We will contact you soon..")
    window.location.reload()
  });
}
  return (
    <>
      <Container fluid>
        <Header></Header>
        <Container>
          <h1 style={{ fontFamily: "Roboto", fontWeight: "700", textAlign: "center", marginTop: "40px" }}>Get in touch</h1>
          <p style={{ fontFamily: "Roboto", fontWeight: "300", textAlign: "center", marginBottom: "40px" }}>Let us give you more details about the special offer website you want us. Please fill out the form below.
          <br />
            We have over 35000 students who were benefitted by us!</p>
          <Row>
            <Col md={7} style={{ marginBottom: "40px" }}>
              {/* <Map></Map> */}
               <div dangerouslySetInnerHTML={{__html:'<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7385.557241253115!2d84.8768729!3d22.2484768!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb5e6c57ff4e27576!2sThe%20Solution%20Point!5e0!3m2!1sen!2sin!4v1638187740350!5m2!1sen!2sin" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"></iframe>' }} />
              
            </Col>
            <Col md={5} style={{ marginBottom: "40px" }}>
              <Form style={{ width: "100%", padding: "20px", background: "#d9d9d9" }}>
                <Form.Group className="mb-3" controlId="formBasicText" onChange = { (event) => setName( event.target.value ) }>
                  <Form.Control type="text" placeholder="Your Name" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicEmail" onChange = { (event) => setEmail( event.target.value ) }>
                  <Form.Control type="email" placeholder="Your Email" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPhone" onChange = { (event) => setPhone( event.target.value ) }>
                  <Form.Control type="text" placeholder="Your Phone" />
                </Form.Group>

                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1" onChange = { (event) => setDesc( event.target.value ) }>
                  <Form.Control as="textarea" rows={3} placeholder="Give Us More Details" />
                </Form.Group>

                <Button variant="primary" onClick={()=>handleSubmit()} >
                  Submit
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
        <Footer></Footer>
      </Container>
    </>
  );
}

export default Contact;