import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import Image from 'react-bootstrap/Image';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
const aboutTitle={
  fontSize: "2em",
  fontFamily: "Roboto",
  fontWeight: 700,
  marginTop: "20px"
}
const aboutText={
  fontSize: "1.3em",
  fontFamily: "Roboto",
  fontWeight: 300,
  textAlign:"justify"
}
const About = () => {
  return (
    <>
    
    <Container fluid>
    <Header></Header>
      <Container>
      <h2 style={aboutTitle}>Welcome to The Solution Point - Rourkela</h2>
        <p style={aboutText}>We envision to provide most accurate and vital information to students at effective cost. We have highly experienced and skilled staff to handel our screening process as a model of excellence and value.</p>
        <p>Since 2002, we have focused to grow with a culture of quality and adaptability. We have encapsulated different technology, process and packages suitable to our students need which have enabled us to adapte the changes in the market from time to time thus delivering results consistently.</p>
      <Row>
        <Col md={4}><Image src="./solo.jpg" fluid /></Col>
        <Col md={8}>
        <h2 style={aboutTitle}>Company Founder</h2>
        <h4>MR. BASANT KUMAR LENKA</h4>
        <h4>(DIRECTOR)</h4>
        <p style={aboutText}>He is the key to the growth and success managing the work and strength putting the worthwhile efforts of Manager, Supervisors and Back Office staffs as well as in field.<br/>
          Working alone at first to build the root of this institute, now he have over 10+ staffs to help him grow this institute.<br/>
          He is the direct answerable and key chain between the Students and the College.</p>          
        </Col>
      </Row>
      <h2 style={aboutTitle}>Why need of a Career Consultant?</h2>
    <p style={aboutText}>There are certain rules & regulation which each & every  colleges follows and those an be different for different colleges. Students find it difficult to proces them and choose what fits best for their current grades and cureent educcational stream. Thus, arises the need of a one stop consultation and guidance system which will help students to persue their career to grow. We have the related information from different colleges and ensure that each and every stuents gets those information to make the educcational career smooth. <br/>
       We are a professionally managed growing institute specialized in the field of linking colleges with students and helping both in different areas to match with the rules & regulation. Over the years, we have helped over 35,000 stuents to choose their career path by guidance and helping them in different colleges rules & regulation.<br/>
       As a matter of policy, we take utmost care to ensure strict adherence to the guidelines as prescribed by the colleges and our staffs are trained on a regular basis to follow this.   <br/>
         </p>

        {/* <h2 style={aboutTitle}>Introduction and Company Profile</h2>
        <p style={aboutText}>R We are a professionally managed growing agency specialized in the field of investigations and fraud control on a varied portfolio. Over the years, we have assimilated the skills and competencies expected of a successful player in this industry and we always want to surpass the expectations of our clients time and again.<br/>
        R As a matter of policy, we take utmost care to ensure strict adherence to the guidelines as prescribed by our clients in fraud control and investigation. Our executives are trained on a regular basis. This is keeping in view the brand equity of our clients.<br/>
        R Our organization structure is akin to a pyramid with each Product Portfolio assigned to a Manager at the top who has a team of supervisors who report to him. These supervisors manage a team of executives who undertake periodic follow-up. The manager is directly answerable to the management. <br/>
        </p>
      
          <h2 style={aboutTitle}>Infrastructure &amp; Manpower</h2>
          <p style={aboutText}>
          Our organization is supported by the following infrastructure.<br/>
          Separate premises approx. 1500 Square feet.  <br/>
          Ten Stationary telephone lines and fax line.<br/>
          Mobile Phones to facilitate supervision work.<br/>
          Pentium based computer systems with Internet connections under LAN.<br/>
          Printer, Scanner and Photocopier to support the system<br/>
          Independent Workstations for our executives. <br/>
          Our manpower consists of the following<br/>
          5 supervisors who manage these teams<br/>
          One Manager<br/>
          Thirty EDP &amp; Backend Executives to support the operations<br/>
          Five Tele callers<br/>
          50 Field Executive to support the operations.<br/>
          We are also in the process of fresh recruitment in fulfillment of our commitment to prompt and efficient service and expanding business. 
          </p> */}
      </Container>
      <Footer></Footer>
    </Container>
    </>
  );
}

export default About;