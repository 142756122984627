import React from "react";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import SwiperCore, {
  Navigation
} from 'swiper/core';
import { Row } from "react-bootstrap";
SwiperCore.use([Navigation]);
const slideSize = {
  background:"url(./slide2.jpg)",
  backgroundSize:"110%"  
}
const overlay = {
    background: "rgba(0,0,0,0.4)",
    display: "block",
    padding:"10%"
}
const title = {
    fontSize:"3em", 
    fontWeight:"900", 
    color:"#fff",
    textAlign:"center",
    textShadow: "0px 3px 5px #000"
}
const tagline = {
    fontSize:"1.3em", 
    fontWeight:"900", 
    color:"#fff",
    textAlign:"center",
    textShadow: "0px 3px 5px #000"
}
const Slider = () => {
  return (
    <>
    <Row>
    <Swiper
      navigation={true}
      spaceBetween={50}
      slidesPerView={1}
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
    >
      <SwiperSlide style={slideSize}>
          <span style={overlay}>
          <h1 style={title}>Welcome To <span style={{color:"#00ff68"}}>The Solution Point</span></h1>
            <h4 style={tagline}>A place where we enlighten the way for students</h4>
            </span>
      </SwiperSlide>
      <SwiperSlide style={slideSize}>
      <span style={overlay}>
          <h1 style={title}>Welcome To <span style={{color:"#00ff68"}}>The Solution Point</span></h1>
            <h4 style={tagline}>Affliated by Maa Saraswati Educational Trust</h4>
            </span>
      </SwiperSlide>

    </Swiper>
    </Row>
    </>
  );
}

export default Slider;